import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"

const LifeStylePage = () => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <div>Life Style</div>

    </Layout>
  )
}

export default LifeStylePage
